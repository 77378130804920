import React, { useContext, useEffect, useMemo, useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';

import { useAuth0 } from '../react-auth0-wrapper';
import { getSchoolIds } from './FetchData';
import { FlashMessageContext } from '../context/flash-message';
import { renderSubheader } from './common';

import '../styles/reports.css';
import logoISR from '../context/PDF.png';


export const Reports = () => {
  /** *********************
   * INITIALIZATION
   ***********************/

  const { getTokenSilently } = useAuth0();
  const flashMessageContext = useContext(FlashMessageContext);

  const [assessments, setAssessments] = useState([]);
  const [isrBundles, setIsrBundles] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  // eslint-disable-next-line no-unused-vars
  const [assessmentId, setAssessmentId] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [reportsAvailable, setReportsAvailable] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [scoreSummaryData, setScoreSummaryData] = useState("");
  // eslint-disable-next-line no-unused-vars
  let reportingGroup = 'all';


  useEffect(() => {
    /**
     * Get the assessmentStudent records for the authenticated school
     *
     * @return {Promise<AssessmentData>}
     */
    const getAssessmentsData = async () => {
      const apiPath = `${process.env.REACT_APP_API_PATH}/assessments`;
      const token = await getTokenSilently();
      const schoolIds = await getSchoolIds(token);

      const httpHeaders = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        'X-School-Ids': schoolIds,
      };

      const requestOptions = {
        method: 'GET',
        headers: httpHeaders,
      }

      return fetch(apiPath, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            setReportsAvailable(true);
            return response.json();
          } else if (response.status === 403 || schoolIds === '') {
            setReportsAvailable(false);
            flashMessageContext.showMessage(`You do not have a School ID assigned to you. Please contact ${process.env.REACT_APP_SUPPORT_EMAIL} for assistance.`);
          } else if (response.status === 404) {
            setReportsAvailable(false);
          } else {
            setReportsAvailable(false);
            flashMessageContext.showMessage(`Status Code: ${response.status} Could not fetch assessments`);
          }
        })
        .then((data) => {
            setAssessments(data.students);
            setIsrBundles(data.isrBundles);
            setAssessmentId(data.students[0].assessmentId);
        })
        .catch((error) => {
          console.error(error);
          flashMessageContext.showMessage(error.message);
        });
    }

    getAssessmentsData();
  }, [flashMessageContext, getTokenSilently]);

  // In the API, this is referred to as assessmentStudent (a list of students who are taking a specfic assessment)
  const sortedAssessments = useMemo(() => {
    return assessments.sort((a, b) => {
      const sort = sortDirection === 'asc' ? 1 : -1;
      let valueA;
      let valueB;
      let comparator;

      // 1. Get the field and do any pre-modifications if needed
      if (sortColumn === 'remainingTasks') {
        valueA = a['totalTasks'] - a['completedTasks'];
        valueB = b['totalTasks'] - b['completedTasks'];
      } else {
        valueA = a[sortColumn];
        valueB = b[sortColumn];
      }

      // 2. Figure out the comparator type
      // Sort by alphabetical
      if (
        sortColumn === 'status' ||
        sortColumn === 'testingGroup' ||
        sortColumn === 'teacher' ||
        sortColumn === 'lastName' ||
        sortColumn === 'firstName' ||
        sortColumn === 'username'
      ) {
        comparator = 'string';
      } else if (sortColumn === 'isLocked') {
        // Sort by boolean
        comparator = 'boolean';
      } else if (
        sortColumn === 'age' ||
        sortColumn === 'studentId' ||
        sortColumn === 'completedTasks' ||
        sortColumn === 'remainingTasks'
      ) {
        // Sort by number
        comparator = 'number';
      } else if (sortColumn === 'lastUpdated') {
        // Sort date
        comparator = 'date';
      }

      // 3. Do the sorting
      if (comparator === 'string') {
        valueA = valueA || '';
        return valueA.localeCompare(valueB) * sort;
      } else if (comparator === 'number' || comparator === 'boolean') {
        return (valueA - valueB) * sort;
      } else if (comparator === 'date') {
        valueA = valueA ? new Date(valueA) : 0;
        valueB = valueB ? new Date(valueB) : 0;
        return (valueA - valueB) * sort * -1;
      } else {
        return assessments;
      }
    });
  }, [assessments, sortColumn, sortDirection]);

  /** *********************
   * HTTP METHODS
   ** *********************/

  /**
   * Download the CSV report of the scores summary  of the assessment
   * @workInProgress
   *
   * @return {Promise<AssessmentData>}
   */
  // eslint-disable-next-line no-unused-vars
  const downloadScoresSummaryReport = () => {
    const fetchCsvData = async () => {
      if (!assessmentId) {
        console.error('No assessmentId to fetch CSV data');
        return;
      }
      try {
        const token = await getTokenSilently();
        const schoolIds = await getSchoolIds(token);
        const apiPath = `${process.env.REACT_APP_API_PATH}/assessments/${assessmentId}/scores_summary_report`;
        const httpHeaders = {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'text/csv',
          'X-School-Ids': schoolIds,
        };
        const requestOptions = {
          method: 'GET',
          headers: httpHeaders,
        };
        const response = await fetch(apiPath, requestOptions);
        if (response.ok) {
          const csvText = await response.text();
          console.debug('csvText', csvText);
          setScoreSummaryData(csvText);
          return csvText;
        } else {
          console.error('Failed to fetch CSV data');
        }
      } catch (error) {
        console.error('Error fetching CSV data:', error);
      }
    }

    let csvText = '';
    if (!scoreSummaryData) {
      csvText = fetchCsvData();
    }

    if (csvText.length === 0) return;

    const blob = new Blob([scoreSummaryData], {type: 'text/csv;charset=utf-8'});
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    console.debug('url', url);
    link.href = url;
    link.setAttribute(
      'download',
      'scores_summary_report.csv'
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /** *********************
   * EVENT HANDLERS
   ***********************/

  function handleSortColumns(column) {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  }

  function handleChangeReportingGroup(e) {
    e.preventDefault();
    reportingGroup = e.target.value;
  }

  function handleBulkDownload() {
    const list = document.getElementById('filterForm.bulkReportSelect');
    const i = list.selectedIndex;
    if (i === 0) {
      flashMessageContext.showMessage('Please select a reporting group', 'info');
      return;
    }
    const url = list.options[i].value;
    window.open(url);
  }

  /** *********************
   * STYLES
   ***********************/

  const headerHoverStyle = { cursor: 'pointer' };

  function renderAssessmentInfo() {
    let assessmentInfo = {};
    for (const assessment of sortedAssessments) {
      assessmentInfo = {
        schoolName: assessment.schoolName,
        schoolCode: assessment.schoolCode,
        startDate: assessment.startDate,
        endDate: assessment.endDate,
        schoolYear: assessment.schoolYear,
      };
      break;
    }
    const formatStartDay = assessmentInfo.startDate + 'edited';
    const formatEndDay = assessmentInfo.endDate + 'edited';
    const dashedStartDay = formatStartDay.substring(0, 10);
    const dashedEndDay = formatEndDay.substring(0, 10);
    const startDay = assessmentInfo.startDate ? dashedStartDay.replaceAll('-', '/') : '';
    const endDay = assessmentInfo.endDate ? dashedEndDay.replaceAll('-', '/') : '';

    return (
      <div className='bottomBoarderLine'>
        <Row className='justify-content-md-center text-muted my-4 text-center'>
          <Col md={6}>
            <h3 className='assessmentStatusTitle'>ASLA Reports Center</h3>
            <span
              className={'font-weight-bold'}>{assessmentInfo.schoolYear ? assessmentInfo.schoolYear + ' Academic Year' : ''}</span>
          </Col>
        </Row>
        <Row className='justify-content-md-center text-muted my-4 text-center'>
          <Col md={6}>
            <h5 className='assessmentStatusTitle'>{assessmentInfo.schoolName}</h5>
            <span className={'font-weight-bold'}>{startDay} - {endDay}</span>
          </Col>
        </Row>
      </div>
    );
  }

  function renderBundlePDFOption(key) {
    const value = isrBundles[key];
    let text;
    let result;
    if (key === 'all') {
      text = 'All Students';
    } else if (key === 'age_at_test') {
      text = 'by Age';
    } else if (key === 'teacher') {
      text = 'by Teachers';
    } else if (key === 'testing_group') {
      text = 'by Testing Group';
    }
    if (value === 'not-found') {
      result = <option value='#'>(not ready yet) {text}</option>;
    } else {
      result = <option value={value}>{text}</option>;
    }
    return result;
  }

  function renderBulkDownload() {
    if (!sortedAssessments[0]) {
      return (
        <div>Loading...</div>
      );
    }

    return (
      <div>
        <Row className='justify-content-md-center my-2 text-center'>
          <Col md={4}>
            <Form className={'filter alert alert-secondary'}>
              <Form.Group controlId='filterForm.bulkReportSelect'>
                <Form.Label>Download Scores</Form.Label>
                <Form.Select onChange={handleChangeReportingGroup}>
                  <option key='blankChoice' hidden value>Select a reporting group</option>
                  {renderBundlePDFOption('all')}
                  {renderBundlePDFOption('age_at_test')}
                  {renderBundlePDFOption('teacher')}
                  {renderBundlePDFOption('testing_group')}
                </Form.Select>
              </Form.Group>
              <Row className='justify-content-md-center my-2 text-center'>
                <Col md={{ span: 'auto' }}>
                  <Button onClick={() => handleBulkDownload()}>
                    Download
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }

  function renderTableHeader() {
    return (
      <thead className='tableHeaderRow'>
        <tr style={headerHoverStyle}>
          {renderSortableTableHeaderField('studentId', 'Student ID')}
          {renderSortableTableHeaderField('username', 'Username')}
          {renderSortableTableHeaderField('firstName', 'First Name')}
          {renderSortableTableHeaderField('lastName', 'Last Name')}
          {renderSortableTableHeaderField('age', 'Age')}
          {renderSortableTableHeaderField('teacher', 'Teacher')}
          {renderSortableTableHeaderField('testingGroup', 'Testing Group')}
          {renderSortableTableHeaderField('status', 'Status')}
          {renderSortableTableHeaderField('isr', 'ISR')}
        </tr>
      </thead>
    );
  }

  function renderSortableTableHeaderField(fieldName, displayName = null) {
    if (!displayName) {
      displayName = fieldName;
    }

    let sortIcon;
    let emphasis;

    if (sortColumn === fieldName) {
      sortIcon = sortDirection === 'asc' ? 'oi oi-arrow-circle-top' : 'oi oi-arrow-circle-bottom';
      emphasis = 'text-warning';
    } else {
      sortIcon = 'oi oi-elevator';
      emphasis = 'text-secondary';
    }

    return (
      <th onClick={() => handleSortColumns(fieldName) } style={{ whiteSpace: 'nowrap' }}>
        <span>
          {displayName}
        </span>
        <span className={`ps-1 ${emphasis} oi ${sortIcon}`}/>
      </th>
    );
  }

  function renderIsrResult(isrPDFS3Key) {
    if (isrPDFS3Key === 'empty' || isrPDFS3Key === 'not-found') {
      return <img src={logoISR} alt='PDF' className='logoPDF noIsr' />;
    } else {
      return <a href={isrPDFS3Key}><img src={logoISR} alt='PDF' className='logoPDF' /></a>;
    }
  }

  function renderAssessmentRows() {
    const rows = [];
    sortedAssessments.forEach((assessment) => {
      rows.push(<tr className={'dataRow'} key={assessment.id}>
        <td className="tableRowDataAlignment">{assessment.studentId}</td>
        <td>{assessment.username}</td>
        <td>{assessment.firstName}</td>
        <td>{assessment.lastName}</td>
        <td className="tableRowDataAlignment">{assessment.age}</td>
        <td>{assessment.teacher}</td>
        <td className="tableRowDataAlignment">{assessment.testingGroup}</td>
        <td>{assessment.status}</td>
        <td className="tableRowDataAlignment">{renderIsrResult(assessment.isrPDFS3Key)}</td>
      </tr>);
    });
    return rows;
  }

  function renderTable() {
    return (
      <div className='tableDiv'>
        <Table bordered striped hover style={{ margin: 0 }}>
          {renderTableHeader()}
          <tbody>
            {renderAssessmentRows()}
          </tbody>
        </Table>
      </div>
    );
  }

  // Main render method
  if (!assessments) {
    return <div>Loading...</div>;
  } else if (!reportsAvailable) {
    return (
      <Container className='text-start'>
        <Row className={'alert alert-primary'}>Assessment Student Reports</Row>
        <Row className={'alert alert-secondary'}>No reports available. Reports will be available after students completes their assessments.</Row>
      </Container>
    );
  } else {
    return (
      <>
        {renderSubheader()}
        <div className={'mb-5'}>
          {renderAssessmentInfo()}
          {renderBulkDownload()}
          {/* <Row className='mt-3 justify-content-center'> */}
          {/*   <Col className={'col-md-8'} /> */}
          {/*   <Col className={'col-md-2 text-end'}> */}
          {/*     <a href="#" onClick={downloadScoresSummaryReport} className={'text-muted small'}>Scores Summary</a> */}
          {/*   </Col> */}
          {/* </Row> */}
          <Row className='justify-content-center'>
            <Col md={10}>
              {renderTable()}
            </Col>
          </Row>
        </div>
      </>
    );
  }
};

/** *********************
 * JSDOC DEFINITIONS
 ***********************/

/**
 * @typedef AssessmentData.students
 * @property {string} testingGroup
 * @property {string} teacher
 * @property {string} lastName
 * @property {string} firstName
 * @property {number} age
 * @property {number} studentId
 * @property {number} completedTasks
 * @property {string} currentTask
 * @property {number} totalTasks
 * @property {Date} lastUpdated
 * @property {string} isrPDF
 * @property {string} isrPDFS3Key
 * @property {string} isrAllBundlesS3Keys
 * @property {string} age_at_test
 * @property {string} testing_group
 * @typedef AssessmentData.isrBundles
 */

export default Reports;
