import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Contexts
import { FlashMessageContext, FlashMessageContextProvider } from './context/flash-message';
import { useAuth0 } from './react-auth0-wrapper';

import { getSchoolIds } from './components/FetchData';
import { labelsAndCopy } from './components/common';

// React Bootstrap
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

// Components
import FlashMessage from './components/FlashMessage';
import Dashboard from './components/Dashboard';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';
import AdminPage from './components/AdminPage';
import HealthCheck from './components/HealthCheck';
import Reports from './components/Reports';
import NDA from './components/NDA';
import Login from './components/Login';

import api from './api';
import './App.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

// eslint-disable-next-line no-unused-vars
function App() {
  const { loading, isAuthenticated, user, getTokenSilently, logout } = useAuth0();
  const currentPath = window.location.pathname;
  const isHealthCheck = currentPath === '/healthcheck';

  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [ndaSigned, setNdaSigned] = useState(false);
  const [ndaLoading, setNdaLoading] = useState(false);
  const [schoolLoading, setSchoolLoading] = useState(false);
  const [schoolData, setSchoolData] = useState(null);
  const flashMessage = useContext(FlashMessageContext);

  useEffect(() => {
    const didUserSign = (signature, user) => {
      const ndaEmail = signature?.user_email
      const userEmail = user?.email

      if (!ndaEmail || !userEmail) {
        return false
      }

      return ndaEmail.toLowerCase().trim() === userEmail.toLowerCase().trim()
    }

    // with a valid user, and no login error message, check if the user has signed the NDA and set the state if not
    const checkNdaSigned = async () => {
      if (user && !loginErrorMessage) {
        setNdaLoading(true);
        api.get(`/admin/nda/signature?email=${encodeURIComponent(user.email)}`)
          .then((signature) => {
            const signed = didUserSign(signature, user)
            setNdaSigned(signed);
            setNdaLoading(false);
          })
          .catch((noSignature) => {
            setNdaLoading(false);
          });
      }
    };

    checkNdaSigned();
  }, [user, loginErrorMessage]);

  useEffect(() => {
    const clickLogout = (message) => {
      logout({ returnTo: window.location.origin });
      // Store the message in local storage so it can be displayed after the redirect
      localStorage.setItem('loginErrorMessage', message);
    }

    // Check if the user has a school and assessment set up and the school has access to the ASLA portal
    const checkSchoolAndAssessment = async () => {
      if (user) {
        setSchoolLoading(true);
        const token = await getTokenSilently();
        const schoolIds = await getSchoolIds(token);
        const schoolId = schoolIds?.split(',')?.[0];

        if (!schoolId) {
          const message = "User token does not have a school id.";
          console.error(message);
          setLoginErrorMessage(message)
          setSchoolLoading(false);
          clickLogout(message);
          return false
        }

        const apiPath = `${process.env.REACT_APP_API_PATH}/schools/${schoolId}/latest-assessment`
        fetch(apiPath)
          .then((response) => {
            if (response.status === 200) {
              response.json().then(json => {
                setSchoolData(json.school);
                setSchoolLoading(false);
                setLoginErrorMessage('');
              }).catch(error => {
                console.error(`JSON Error: ${error}`);
                setSchoolLoading(false);
              })
            } else {
              let message = "School doesn't have access or assessment is not set up correctly.";
              console.error(message, response);
              if (response.status === 403) {
                message = "School doesn't have access to ASLA portal.";
              } else {
                message = "Assessment is not set up correctly for this school.";
                console.debug(message);
              }
              setLoginErrorMessage(message)
              setSchoolLoading(false);
              clickLogout(message);
            }
          })
          .catch((error) => {
            console.error(error);
            setSchoolLoading(false);
            clickLogout(error.toString());
          });
      }
    };

    checkSchoolAndAssessment();
  }, [user, ndaSigned, logout, getTokenSilently, flashMessage, loginErrorMessage])

  if (loading || ndaLoading || schoolLoading) {
    return (
      <div>Loading...</div>
    );
  }

  const renderNavBar = () => {
    return (
      <NavBar
        isNdaSigned={ndaSigned}
        isHealthCheck={isHealthCheck}
        isAuthenticated={isAuthenticated}
      />
    );
  };

  const renderFlashMessage = () => {
    return (
      <FlashMessage />
    );
  };

  const renderNDA = () => {
    return (
      <NDA
        isNdaSigned={ndaSigned}
      />
    );
  };

  // if user is authenticated but hasn't signed the NDA, return the NDA component once it has loaded
  if (isAuthenticated && !ndaSigned) {
    return (
      <FlashMessageContextProvider>
        {renderNavBar()}
        {renderFlashMessage()}
        {renderNDA()}
      </FlashMessageContextProvider>
    );
  }

  // Show the home page, passing loaded school name and any login error message
  const HomeWithSchoolInfo = () => <Home schoolName={schoolData?.name} loginErrorMessage={loginErrorMessage} />

  return (
    <FlashMessageContextProvider>
      <BrowserRouter>
        {renderNavBar()}
        {renderFlashMessage()}
        <Switch>
          <Route path='/' exact component={HomeWithSchoolInfo} />
          <Route path='/healthcheck' exact component={HealthCheck} />
          <PrivateRoute path='/dashboard' component={Dashboard} />
          <PrivateRoute path='/reports' component={Reports} />
          <PrivateRoute path='/admin' component={AdminPage} />
          <PrivateRoute path='/login' component={Login} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </FlashMessageContextProvider>
  );
}


// Home component
const Home = ({ schoolName, loginErrorMessage }) => {
  const { isAuthenticated, isAdmin, isProctor } = useAuth0();
  const [loginMessage, setLoginMessage] = useState('');

  // Since error results in logout, so loss of session state, we need to store the message in local storage
  const msg = localStorage.getItem('loginErrorMessage');
  localStorage.removeItem('loginErrorMessage');
  if (msg) {
    setLoginMessage(msg);
  }

  function renderButton(label, href) {
    return (
      <>
        <Col md={4}>
          <Button
            className={'btn-block'}
            variant={'success'}
            size={'lg'}
            href={href}
          >
            {label}
          </Button>
        </Col>
      </>
    );
  }

  function renderCopy(copy) {
    return (
      <>
        <Col md={4}>{copy}</Col>
      </>
    );
  }

  function renderButtons() {
    if (isAuthenticated && isAdmin) {
      return (
        <>
          <Row className="pt-4">
            {renderButton(labelsAndCopy.admin.label, '/admin')}
            {renderButton(labelsAndCopy.dashboard.label, '/dashboard')}
            {renderButton(labelsAndCopy.reports.label, '/reports')}
          </Row>
          <Row className="pt-3">
            {renderCopy(labelsAndCopy.admin.copy)}
            {renderCopy(labelsAndCopy.dashboard.copy)}
            {renderCopy(labelsAndCopy.reports.copy)}
          </Row>
        </>
      );
    } else if (isAuthenticated && isProctor) {
      return (
        <>
          <Row className="pt-4">
            <Col md={4} />
            {renderButton(labelsAndCopy.dashboard.label, '/dashboard')}
          </Row>
          <Row className="pt-3">
            <Col md={4} />
            {renderCopy(labelsAndCopy.dashboard.copy)}
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row className="pt-4">
            <Col md={4} />
            {renderButton(labelsAndCopy.login.label, '/login')}
          </Row>
          <Row className="pt-3">
            <Col md={4} />
            {renderCopy(labelsAndCopy.login.copy)}
          </Row>
        </>
      );
    }
  }

  // Displays info about the user perms and login status
  function renderWelcome() {
    let loginStatus; let loginRole; let messageStyle;
    if (isAuthenticated) {
      loginStatus = 'You are logged in';
      messageStyle = 'text-success';
      loginRole = isAdmin ? ' as a School Test Administrator (STC)' : ' as a Proctor.';
    } else if (loginMessage) {
      loginStatus = `${loginMessage}  Please contact ASLA Support at ${process.env.REACT_APP_SUPPORT_EMAIL} for information.`;
      messageStyle = 'text-danger';
      loginRole = '';
    } else {
      loginStatus = 'You are not logged in.';
      messageStyle = 'text-info';
      loginRole = '';
    }
    return (
      <>
        <Row className='alert alert-light justify-content-center'><span className={messageStyle}>{loginStatus}{loginRole}</span></Row>
      </>
    );
  }

  function renderSchool() {
    return (<h2>{schoolName ?? ''}</h2>) || null
  }

  return (
    <Container className='welcome-container'>
      <h1>Welcome to the ASLA Portal</h1>
      {renderSchool()}
      {renderWelcome()}
      {renderButtons()}
    </Container>
  );
};

export default App;
