import * as React from 'react';

const Fallback = () => {
  return (
    <section id="fallback">
      <h1>Uh, oh.</h1>
      <p>Something went wrong. Sorry about that.</p>
      <p>Try again later.</p>
      <p>
        If that doesn't work for you, contact
        us at <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL || 'support@asledcenter.org'}</a>.
      </p>
    </section>
  )
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error)
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback ?? <Fallback />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;